<template>
	<div class="container">

		<div class="row">
			<div class="col-10">
				<v-form ref="form" class="brand-form">
					<InputTextField
						:id="'new_course_name'"
						v-model="courseName"
						:value="courseName"
						:rules="[rules.required]"
					>
						<template #label>Course Name</template>
					</InputTextField>
					<InputTextArea
						v-model="courseDescription"
						:id="'new_course_description'"
					>
						<template #label> Description (Optional)</template>
					</InputTextArea>
					<InputImageFile
						v-model="newImg"
						:valueName="'homePageMainImage'"
						:id="'new_course_main_image'"
						:name="'new_course_main_image'"
					>
						<template #span
							>COURSE FEATURED IMAGE (OPTIONAL)</template
						>
						<template #button>Choose File</template>
					</InputImageFile>
				</v-form>
			</div>
		</div>

		<div class="col-10">
			<div class="row justify-content-center align-items-center">
				<div class="col text-right">
					<v-btn
						class="admin-primary-button primary-contrast-background shadow-none"
						:loading="isLoading"
						@click="save"
						>Done</v-btn
					>
				</div>
			</div>
		</div>

	</div>
	<!-- <v-container class="main-container">
		<v-row>
			<v-col cols="7">
				<v-form ref="form">
					<InputTextField
						:id="'new_course_name'"
						v-model="courseName"
						:value="courseName"
						:rules="[rules.required]"
					>
						<template #label>Course Name</template>
					</InputTextField>
					<InputTextArea
						v-model="courseDescription"
						:id="'new_course_description'"
					>
						<template #label> Description (Optional)</template>
					</InputTextArea>
					<InputImageFile
						v-model="newImg"
						:valueName="'homePageMainImage'"
						:id="'new_course_main_image'"
						:name="'new_course_main_image'"
					>
						<template #span
							>Course Featured Image (Optional)</template
						>
						<template #button>Choose File</template>
					</InputImageFile>
				</v-form>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					@click="save"
					>Done</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import InputImageFile from "@/components/InputImageFile.vue";
import InputTextField from "@/components/InputTextField.vue";
import { mapGetters } from "vuex";
import { put } from "@/util/requests/put";
import { post } from "@/util/requests/post";
import { remove } from "@/util/requests/remove";

export default {
	name: "CourseSettingsGeneral",
	components: {
		InputImageFile,
		InputTextField
	},
	props: ["newCourseName", "newCourseDescription", "courseId", "course"],

	computed: {
		...mapGetters(["isLoading"]),
		courseName: {
			get() {
				return this.newCourseName;
			},
			set(val) {
				this.$emit("setValue", { key: "newCourseName", value: val });
			}
		},
		courseDescription: {
			get() {
				return this.newCourseDescription;
			},
			set(val) {
				this.$emit("setValue", {
					key: "newCourseDescription",
					value: val
				});
			}
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			newImg: null
		};
	},
	methods: {
		async save() {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await put("/course", this.courseId, {
					user_id: this.course.user_id,
					organization_id: this.course.organization_id,
					course_type_id: this.course.course_type_id,
					title: this.course.title,
					description: this.course.description,
					duration: this.course.duration,
					category: this.course.category,
					custom: this.course.custom,
					parent_course_id: this.course.parent_course_id,
					locked_by_parent: this.course.locked_by_parent,
					category_id: this.course.category_id,
					published_at: this.course.published_at
				});
				if (this.newImg !== null) {
					if (response.status === 200) {
						if (this.course.images.length !== 0) {
							await remove(
								`/course_image/${this.course.images[0].id}`
							);
						}

						await post(
							"/course_image",
							{
								course_id: this.courseId,
								file: this.newImg,
								comment: "comment"
							},
							{
								headers: {
									"Content-Type": "multipart/form-data"
								}
							}
						);
					}
				}
			} catch (error) {
				console.log(error);
				this.$emit("setValue", {
					key: "message",
					value: error.response.data.message
				});
			}
			await this.$store.dispatch("setIsLoading", false);
		}
	}
};
</script>

<style lang="scss">
#CourseSettingsGeneral {
	.image-file-input {
		max-width: 100% !important;
	}
}
</style>
