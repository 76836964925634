<template>

	<div class="container">

		<div class="row">
			<div class="col-6">
				<v-form ref="form" class="brand-form">
					<p class="mb-2">QUIZ ASSIGNED</p>
						<v-chip
							v-if="assignedQuizTitle"
							large
							close
							close-icon="mdi-delete"
							:loading="isLoading"
							class="ml-4"
							@click:close="removeAssign"
						>
							<span class="mr-2">{{ assignedQuizTitle }}</span>
						</v-chip>
						<article class="mt-15">
								<v-autocomplete
									v-model="quizId"
									:items="allQuizzes"
									:item-text="'name'"
									:item-value="'id'"
									:loading="isLoading"
									outlined
									class="mt-10"
									label="Search for Courses"
								></v-autocomplete>
							</article>
							</v-form>
			</div>
		</div>

		<div class="col-10">
			<div class="row justify-content-center align-items-center">
				<div class="col text-right">
					<v-btn
						class="admin-primary-button primary-contrast-background shadow-none"
						@click="assignQuiz"
						>Assign</v-btn
					>
					<v-btn
						class="admin-primary-button primary-contrast-background shadow-none ml-5"
						@click="createNewQuiz"
						>Create new quiz</v-btn
					>
				</div>
			</div>
		</div>

	</div>

	<!-- <v-container class="main-container">
		<v-row>
			<v-col cols="8">
				<article>
					<v-row class="align-center mx-0">
						<p class="mb-2">Quiz assigned:</p>
						<v-chip
							v-if="assignedQuizTitle"
							large
							close
							close-icon="mdi-delete"
							:loading="isLoading"
							class="ml-4"
							@click:close="removeAssign"
						>
							<span class="mr-2">{{ assignedQuizTitle }}</span>
						</v-chip>
					</v-row>
					<v-row>
						<v-col cols="4">
							<article class="mt-15">
								<v-autocomplete
									v-model="quizId"
									:items="allQuizzes"
									:item-text="'name'"
									:item-value="'id'"
									:loading="isLoading"
									outlined
									class="mt-10"
									label="Search for Courses"
								></v-autocomplete>
							</article>
						</v-col>
					</v-row>
				</article>
			</v-col>
		</v-row>
		<v-row class="align-center mx-0">
			<v-btn
				class="admin-primary-button primary-contrast-background"
				@click="assignQuiz"
				>Assign</v-btn
			>
			<p class="mx-10 mb-0">OR</p>
			<v-btn
				class="admin-primary-button primary-contrast-background"
				@click="createNewQuiz"
				>Create new quiz</v-btn
			>
		</v-row>
	</v-container> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "CourseSettingsQuiz",

	props: {
		course: {
			type: Object,
			require: true
		},
		quizAssignedId: {
			type: Number,
			require: true
		},
		assignedQuizTitle: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading", "allQuizzes"]),

		quizId: {
			get() {
				return this.quizAssignedId;
			},
			set(val) {
				this.$emit("setValue", {
					key: "quizAssignedId",
					value: val
				});
			}
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			rates: ["50%", "60%", "70%", "80%"]
		};
	},
	methods: {
		async assignQuiz() {
			this.$emit("assignQuiz");
		},
		async removeAssign() {
			this.$emit("removeAssign");
		},
		createNewQuiz() {
			this.$router.push({
				name: "NewQuiz"
			});
		}
	}
};
</script>

<style></style>
