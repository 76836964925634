<template>
	<div class="container">
		<div class="row header align-end">
			<div class="col-6">
				<backTo
					:link="`/admin/courses-editor/${courseId}`"
					><template #text>Back</template></backTo
				>
				<h3 class="mt-5">Course Settings</h3>
			</div>
		</div>

		<div class="brand-tabs">
			<v-tabs
				v-model="currentItem"
				light
				background-color="transparent"
				:slider-color="$store.getters.theme.mainAccentColor"
				slider-size="8"
				color="#000"
				class="brand-tabs-container"
			>
				<v-tab
					v-for="(tab, index) in tabs"
					:key="index"
					:href="`#${tab.component}`"
					class="organization-settings-tab-button primary-text"
					@click="currentItem = tab.component"
				>
					{{ tab.title }}
				</v-tab>
			</v-tabs>

			<v-tabs-items
				v-model="currentItem"
				class="organization-settings-tabs-container background-transparent"
			>
				<v-tab-item
					v-for="tab in tabs"
					:key="tab.title"
					:value="tab.component"
					class="organization-settings-tab"
					><keep-alive>
						<component
							:is="tab.component"
							:courseId="courseId"
							:course="course"
							:newCourseName="newCourseName"
							:newCourseDescription="newCourseDescription"
							:newCourseDiploma="newCourseDiploma"
							:newCourseNameDiploma="newCourseNameDiploma"
							:newCourseDescriptionDiploma="
								newCourseDescriptionDiploma
							"
							:newCourseAssignedUsers="newCourseAssignedUsers"
							:newCourseAssignedQuiz="newCourseAssignedQuiz"
							:quizAssignedId="quizAssignedId"
							:assignedQuizTitle="assignedQuizTitle"
							@setValue="setValue"
							@getCourse="getCourse"
							@assignQuiz="assignQuiz"
							@removeAssign="removeAssign"
						/>
					</keep-alive>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
	<!-- <v-container class="main-container">
		<backTo
			elClass="mb-10 d-block"
			:link="`/admin/courses-editor/${courseId}`"
			><template #text>Back</template></backTo
		>
		<AdminDefaultSubheader>{{ courseTitle }}</AdminDefaultSubheader>
		<AdminDefaultHeader class="mt-5">Course Settings</AdminDefaultHeader>
		<article class="mt-15">
			<v-tabs
				v-model="currentItem"
				light
				background-color="transparent"
				:slider-color="$store.getters.theme.mainAccentColor"
				slider-size="8"
				color="#000"
				class="organization-settings-tab-buttons-container"
			>
				<v-tab
					v-for="(tab, index) in tabs"
					:key="index"
					:href="`#${tab.component}`"
					class="organization-settings-tab-button primary-text"
					@click="currentItem = tab.component"
				>
					{{ tab.title }}
				</v-tab>
			</v-tabs>

			<v-tabs-items
				v-model="currentItem"
				class="organization-settings-tabs-container background-transparent"
			>
				<v-tab-item
					v-for="tab in tabs"
					:key="tab.title"
					:value="tab.component"
					class="organization-settings-tab"
					><keep-alive>
						<component
							:is="tab.component"
							:courseId="courseId"
							:course="course"
							:newCourseName="newCourseName"
							:newCourseDescription="newCourseDescription"
							:newCourseDiploma="newCourseDiploma"
							:newCourseNameDiploma="newCourseNameDiploma"
							:newCourseDescriptionDiploma="
								newCourseDescriptionDiploma
							"
							:newCourseAssignedUsers="newCourseAssignedUsers"
							:newCourseAssignedQuiz="newCourseAssignedQuiz"
							:quizAssignedId="quizAssignedId"
							:assignedQuizTitle="assignedQuizTitle"
							@setValue="setValue"
							@getCourse="getCourse"
							@assignQuiz="assignQuiz"
							@removeAssign="removeAssign"
						/>
					</keep-alive>
				</v-tab-item>
			</v-tabs-items>
		</article>
		<form-message v-if="message" :type="status">{{ message }}</form-message>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import CourseSettingsAssign from "@/views/admin/course-settings/CourseSettingsAssign.vue";
import CourseSettingsDiploma from "@/views/admin/course-settings/CourseSettingsDiploma.vue";
import CourseSettingsGeneral from "@/views/admin/course-settings/CourseSettingsGeneral.vue";
import CourseSettingsQuiz from "@/views/admin/course-settings/CourseSettingsQuiz.vue";
import BackTo from "@/components/BackTo.vue";
import FormMessage from "@/components/FormMessage.vue";
import { get } from "@/util/requests/get";
import { post } from "@/util/requests/post";
import { put } from "@/util/requests/put";
import { remove } from "@/util/requests/remove";

export default {
	name: "CourseSettings",
	components: {
		AdminDefaultSubheader,
		AdminDefaultHeader,
		BackTo,
		CourseSettingsAssign,
		CourseSettingsDiploma,
		CourseSettingsGeneral,
		CourseSettingsQuiz,
		FormMessage
	},
	computed: {
		courseId() {
			return Number(this.$route.params.id);
		},
		courseTitle() {
			if (!!this.course === false) return;
			return String(this.course?.title);
		},
		assignedQuizTitle() {
			if (
				!!this.course?.quizzes === false ||
				!!this.course?.quizzes[0] === false
			)
				return;
			return String(this.course?.quizzes[0].quiz?.name);
		}
	},
	data() {
		return {
			currentItem: "CourseSettingsGeneral",

			rules: {
				required: value => !!value || "Required."
			},
			tabs: [
				{
					title: "General",
					value: "general",
					component: "CourseSettingsGeneral"
				},
				{
					title: "Diploma",
					value: "diploma",
					component: "CourseSettingsDiploma"
				},
				{
					title: "Assign",
					value: "assign",
					component: "CourseSettingsAssign"
				},
				{
					title: "Quiz",
					value: "quiz",
					component: "CourseSettingsQuiz"
				}
			],
			message: "",
			status: "",
			newCourseName: "",
			newCourseDescription: "",
			newCourseDiploma: "",
			newCourseNameDiploma: "",
			newCourseDescriptionDiploma: "",
			newCourseAssignedUsers: "",
			newCourseAssignedQuiz: "",
			quizAssignedId: null,
			course: null
		};
	},
	methods: {
		async getCourse() {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await get(`/course/${this.courseId}`);
				if (response.status === 200) {
					const {
						data: { data }
					} = response;
					this.course = data;
					this.newCourseName = data.title;
					this.newCourseDescription = data.description;
				} else {
					this.$router.push({ name: "CourseEditor" });
				}
			} catch (error) {
				await this.setMessage(error.response.data.message);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		setMessage(val) {
			this.message = val;
		},
		setValue(payload) {
			this[payload.key] = payload.value;
		},
		async assignQuiz() {
			await this.$store.dispatch("setIsLoading", true);
			this.message = "";
			this.status = "";
			try {
				const payload = {
					course_id: this.courseId,
					quiz_id: this.quizAssignedId
				};
				if (this.assignedQuizTitle) {
					const response = await put(
						"/course_quiz",
						this.course.quizzes[0].id,
						payload
					);
				} else {
					const response = await post("/course_quiz", payload);
				}

				this.message = "Success";
				this.status = "success";
				await this.getCourse();
			} catch (error) {
				console.error(error);
				this.message = error;
				this.status = "error";
			}

			await this.$store.dispatch("setIsLoading", false);
		},
		async removeAssign() {
			await this.$store.dispatch("setIsLoading", true);
			this.message = "";
			this.status = "";
			try {
				await remove(`/course_quiz/${this.course.quizzes[0].id}`);
				await this.getCourse();
				this.message = "Success";
				this.status = "success";
				await this.$store.dispatch("setIsLoading", false);
			} catch (error) {
				console.error(error);
				this.message = error;
			}
		}
	},
	async created() {
		await this.getCourse();
	}
};
</script>

<style></style>
